import React from 'react';
import styled from 'styled-components';
import { FaTelegramPlane } from 'react-icons/fa'; // Icon for Telegram
import bannerImage from '../assets/banner.webp'; // Import the first image
import bannerImage2 from '../assets/banner2.webp'; // Import the second image

const About = () => {
  return (
    <Wrapper id="about">
      <BannerImageLeft src={bannerImage2} alt="Banner Image Left" />
      <BannerImageRight src={bannerImage} alt="Banner Image Right" />
      
      <ContentWrapper>
        <HeaderText>
          Welcome to MIND
        </HeaderText>
        <Description>
          We are a passionate community supporting builders and innovators on Solana. At MIND, we believe in
          empowering creators to make a lasting impact in the decentralized world. Join us as we support the next
          generation of projects, tools, and services that push the Solana ecosystem forward.
        </Description>
        <ButtonWrapper>
          <StyledButton href="https://forms.gle/UNT7ZgXmdMjjGPyP7" target="_blank" rel="noopener noreferrer">
            <FaTelegramPlane style={{ marginRight: '10px' }} /> Inquiries
          </StyledButton>
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
`;

const BannerImageLeft = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30%;
  height: auto;
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 80%);
  z-index: 0;
`;

const BannerImageRight = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30%;
  height: auto;
  clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 80%);
  z-index: 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderText = styled.h1`
  font-family: 'Bebas Neue', sans-serif;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0.1em;
  max-width: 600px;
  margin-bottom: 20px;
  color: #ffcc00; /* New color for better contrast */
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;

  /* Add text shadow for depth */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

  /* Outline for better contrast */
  -webkit-text-stroke: 2px #000;

  @media (max-width: 768px) {
    font-size: 50px;
    max-width: 80%;
  }

  @media (max-width: 480px) {
    font-size: 40px;
    max-width: 90%;
  }
`;

const Description = styled.p`
  max-width: 600px;
  font-size: 18px;
  color: #f0f0f0;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`;

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  border: 2px solid #ffcc00; /* Same color as the title */
  border-radius: 50px;
  color: #ffcc00; /* Same color as the title */
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffcc00; /* Button background on hover */
    color: #121212; /* Text color on hover */
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 8px 20px;
  }
`;

export default About;

